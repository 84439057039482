<template>
  <v-app>
    <v-main>
      <SearchComponent />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  components: {
    SearchComponent: () => import('./components/SearchComponent.vue'),
  },

  data: () => ({

  }),

};
</script>

<style lang="scss">
  *{ text-transform: none !important; }
  .logobox {
    height: 90px;
    position: absolute; left:15px; top:15px;
    img {
      display: block; width: auto; height: 100%;
    }
  }

  h1 {
    font-size: 30px;
    margin: 15px 0 15px 0;
     @media (max-width: 767px) {
        text-align: center;
        font-size: 20px;
      }
  }

  .ro-desk {
      @media (max-width: 767px) {
        display: none;
      }
  }
  .ro-mob {
      @media (min-width: 768px) {
        display: none;
      }
  }

  .ro-menu {
    display: flex;
    justify-content: flex-end;
    margin: 0; padding: 15px; list-style: none;
    li { margin:0; padding:0; padding-left: 5px;}
  }
  .v-main {
    padding-top: 30px !important
  }

   .close {
    position: absolute;
    width: 30px;
    height: 30px;
    padding: 30px;
    right: 0;
    top: 0;
    color: $primary-text;
    cursor: pointer;

    &::before,
    &::after {
      position: absolute;
      top: 30px;
      right: 20px;
      content: '';
      width: 20px;
      height: 2px;
      background: $primary-text;
      display: block;

    }
    &:hover {
      &::before,
      &::after {
          background: $secondary-text;
      }
    }

    &::before {
      transform: rotate(45deg);
    }

    &::after {
      transform: rotate(-45deg);
    }

  }

</style>
