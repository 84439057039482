<template>
  <div class="loading-overlay"><span id="rospinner" class="spinner"></span></div>
</template>

<script>
export default {
  name: 'LoadingComponent',
  data: () => ({
  })
}
</script>

<style scoped>

  @keyframes spinner {
    to {transform: rotate(360deg);}
  }

  .spinner:before {
    content: '';
    box-sizing: border-box;
    position: absolute;
    top: 49%;
    left: 48%;
    width: 90px;
    height: 90px;
    margin-top: -10px;
    margin-left: -10px;
    border-radius: 50%;
    border: 20px solid transparent;
    border-top-color: #3a7887;
    border-bottom-color: #549950;
    animation: spinner .8s ease infinite;
  }

  .loading-overlay {
    width: 100%;
    height: 100%;
    position: fixed;
    top:0; left:0;
    z-index:555;
    background: rgba(255,255,255, .6)
    /* background: rgba(255,255,255, .6) url('https://demo.v3.mojawizyta.eu/images/preloader.gif') no-repeat center center; */
  }
</style>
