import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

// import pl from 'vuetify/src/locale/pl'

Vue.use(Vuetify);

export default new Vuetify({



    theme: {
        light: true,
        themes: {
            light: {
                secondary: '#549950',
                primary: '#3a7887',
                accent: '#8c9eff',
                error: '#b71c1c',
                info: '#66b8cd',
            },
        },
    },
})