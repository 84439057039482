import Vue from 'vue';
import api from './services/api';
import App from './App.vue';
import router from './router';
import vuetify from './plugins/vuetify';
import Lottie from 'vue-lottie';
Vue.component('lottie', Lottie);
import LoadingComponent from '@/components/LoadingComponent.vue';
Vue.component('LoadingComponent', LoadingComponent);
import RoalertComponent from '@/components/RoalertComponent.vue';
Vue.component('RoalertComponent', RoalertComponent);

import VueFilterDateParse from '@vuejs-community/vue-filter-date-parse';
Vue.use(VueFilterDateParse);

import VueFilterDateFormat from 'vue-filter-date-format';
Vue.use(VueFilterDateFormat, {
    dayOfWeekNames: [
        'Niedziela', 'Poniedziałek', 'Wtorek', 'Środa', 'Czwartek',
        'Piątek', 'Sobota'
    ],
    dayOfWeekNamesShort: [
        'Su', 'Mo', 'Tu', 'We', 'Tr', 'Fr', 'Sa'
    ],
    monthNames: [
        'stycznia', 'lutego', 'marca', 'kwietnia', 'maja', 'czerwca',
        'lipca', 'sierpnia', 'września', 'października', 'listopada', 'grudnia'
    ],
    monthNamesShort: [
        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
        'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
    ]
});

Vue.prototype.$api = api;

Vue.config.productionTip = false;

Vue.filter('godzfilter', function(godzArray) {
    const modified = godzArray.map(name => `${name}:00`);
    return modified;
});

Vue.filter('formatDate', function(datArray) {
    if (datArray) {
        const modified = datArray.map(dat => moment(String(dat)).format('DD.MM.YYYY'));
        return modified;
    }
});

Vue.filter('plnfilter', function(cena) {
    const modified = `${cena} zł`;
    return modified;
});

Vue.filter('czasfilter', function(czas) {
    const modified = `${czas} min.`;
    return modified;
});


new Vue({
    router,
    vuetify,
    lang: {
        current: 'pl'
    },
    render: (h) => h(App),
}).$mount('#app');