<template>
  <div>
  <v-alert
      border="bottom"

      type="error"
      dark
      tile
    >
      <slot></slot>
    </v-alert>
  </div>
</template>

<script>
export default {
  name: 'RoalertComponent',

}
</script>

