import axios from 'axios';

const API_BASE_URL = document.querySelector("meta[name='api-url']").content;
const csrf = document.querySelector("meta[name='csrf-token']").getAttribute("content");
const HTTP = axios.create({
    headers: { 'X-CSRF-Token': csrf }
});

export default {
    getData(endpoint) {
        return HTTP.get(API_BASE_URL + endpoint);
    },
    postData(endpoint, data) {
        return HTTP.post(API_BASE_URL + endpoint, data);
    },
    postFile(endpoint, data, params) {
        return HTTP.post(API_BASE_URL + endpoint, data, params);
    },
};